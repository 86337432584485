import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Page from '../../templates/Page'
import ProductGrid from '../../components/ProductGrid'
import CollectionList from '../../components/CollectionList'
import { transformTranslation } from '../../utils/helpers'
import { Grid } from './styles'
import { breakpoints, Spacer } from '../../utils/styles'
import { useWindowDimensions } from '../../utils/hooks'

const CollectionPage = ({ data, pageContext }) => {
  const { products, translatedProducts, categories, translatedCategories } =
    data
  const { title, description } = pageContext
  const { width } = useWindowDimensions()
  const { t } = useTranslation()

  const tProducts = transformTranslation(products, translatedProducts)
  const tCategories = transformTranslation(categories, translatedCategories)

  return (
    <Page title={t(title)} description={description}>
      <Grid>
        {width > breakpoints.s && <CollectionList categories={tCategories} />}
        <ProductGrid products={tProducts} />
      </Grid>
      <Spacer height="5rem" />
    </Page>
  )
}

export const query = graphql`
  query ($language: String!, $handle: String!) {
    categories: allShopifyCollection(filter: { title: { ne: "Frontpage" } }) {
      edges {
        node {
          id
          handle
          title
          productsCount
          storefrontId
        }
      }
    }
    translatedCategories: allShopifyTranslatedCollection(
      filter: { locale: { eq: $language } }
    ) {
      edges {
        node {
          title
          description
          descriptionHtml
          locale
          storefrontId
        }
      }
    }
    products: allShopifyProduct(
      filter: { collections: { elemMatch: { handle: { eq: $handle } } } }
    ) {
      edges {
        node {
          id
          title
          handle
          storefrontId
          featuredImage {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          variants {
            compareAtPrice
            availableForSale
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    translatedProducts: allShopifyTranslatedProduct(
      filter: { locale: { eq: $language } }
    ) {
      edges {
        node {
          id
          title
          locale
          storefrontId
        }
      }
    }
    i18nLocales: allLocale(
      filter: {
        ns: { in: ["common", "categories"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CollectionPage
