import React, { useContext } from 'react'
import { Link, Trans } from 'gatsby-plugin-react-i18next'
import { GatsbyImage } from 'gatsby-plugin-image'
import { every } from 'lodash'

import {
  Grid,
  Title,
  Item,
  Price,
  ComparePrice,
  ItemBanner,
  ItemContent,
} from './styles'
import { colors } from '../../utils/styles'
import { calcuateGrossPrice } from '../../utils/helpers'
import StoreContext from '../../context/StoreContext'

const ProductGrid = ({ products }) => {
  const { countryCode } = useContext(StoreContext)

  const i18nPrice = (price, currencyCode) =>
    Intl.NumberFormat(undefined, {
      currency: currencyCode ? currencyCode : 'EUR',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  const priceString = priceRangeV2 => {
    const { minVariantPrice, maxVariantPrice } = priceRangeV2

    if (maxVariantPrice.amount === minVariantPrice.amount) {
      return `${i18nPrice(
        calcuateGrossPrice(maxVariantPrice.amount, countryCode),
        maxVariantPrice.currencyCode
      )}`
    }
    return `${i18nPrice(
      calcuateGrossPrice(minVariantPrice.amount, countryCode),
      minVariantPrice.currencyCode
    )} - ${i18nPrice(
      calcuateGrossPrice(maxVariantPrice.amount, countryCode),
      maxVariantPrice.currencyCode
    )}`
  }

  const areAllVariantsOutOfStock = variants =>
    every(variants, ['availableForSale', false])

  return (
    <Grid>
      {products.map(
        ({ id, handle, title, featuredImage, priceRangeV2, variants }) => (
          <Link
            key={id}
            to={`/shop/${handle}/`}
            style={{ textDecoration: 'none' }}
          >
            <Item>
              <ItemContent>
                {variants[0]?.compareAtPrice && (
                  <ItemBanner color={colors.androidGreen}>
                    <Trans>Sale</Trans>
                  </ItemBanner>
                )}
                {areAllVariantsOutOfStock(variants) && (
                  <ItemBanner color={colors.fireEngineRed}>
                    <Trans>Sold out</Trans>
                  </ItemBanner>
                )}

                <GatsbyImage
                  image={
                    featuredImage.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={title}
                />
                <Title style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  {title}
                </Title>
                <span>
                  {variants[0]?.compareAtPrice && (
                    <ComparePrice>
                      {calcuateGrossPrice(
                        variants[0]?.compareAtPrice,
                        countryCode
                      )}
                    </ComparePrice>
                  )}
                  <Price>{priceString(priceRangeV2)}</Price>
                </span>
              </ItemContent>
            </Item>
          </Link>
        )
      )}
    </Grid>
  )
}

export default ProductGrid
